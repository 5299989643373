export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const BLOCKED_USER = "BLOCKED_USER";
export const UPDATE_HAS_BLOCKED_USER = "UPDATE_HAS_BLOCKED_USER";
export const UPDATE_IS_BLOCKED_USER = "UPDATE_IS_BLOCKED_USER";

export const NEW_ACTIVE_THREAD = "NEW_ACTIVE_THREAD";
export const EMPTY_ACTIVE_THREAD = "EMPTY_ACTIVE_THREAD";
export const CREATE_THREAD = "CREATE_THREAD";
export const FETCH_CONVERSATION_MESSAGES = "FETCH_CONVERSATION_MESSAGES";
export const FETCH_CONVERSATION_STATUS = "FETCH_CONVERSATION_STATUS";
export const FETCH_FILTER_MESSAGES = "FETCH_FILTER_MESSAGES";
export const IS_FETCHING_SELECTED_THREAD = "IS_FETCHING_SELECTED_THREAD";
export const SELECTED_THREAD = "SELECTED_THREAD";
export const SEND_NEW_MESSAGE = "SEND_NEW_MESSAGE";
export const RESEND_MESSAGE = "RESEND_MESSAGE";
export const SEND_MESSAGE_STATUS = "SEND_MESSAGE_STATUS";
export const FILTER_MESSAGES = "FILTER_MESSAGES";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const RECENT_UPDATED = "RECENT_UPDATED";
export const SAVE_THREADS = "SAVE_THREADS";
export const GET_THREADS = "GET_THREADS";
export const GET_THREADS_RECENT = "GET_THREADS_RECENT";
export const ARCHIVE_MESSAGE = "ARCHIVE_MESSAGE";
export const REMOVE_THREAD = "REMOVE_THREAD";
export const UPDATE_A_MESSAGE = "UPDATE_A_MESSAGE";
export const REMOVE_THREAD_UNREAD = "REMOVE_THREAD_UNREAD";
export const SET_RICH_TEXT = "SET_RICH_TEXT";

export const UPDATE_THREAD_SEEN = "UPDATE_THREAD_SEEN";
export const UPDATE_THREAD_UNSEEN = "UPDATE_THREAD_UNSEEN";

export const FILTER_GROUPS = "FILTER_GROUPS";
export const FILTER_PATIENTS = "FILTER_PATIENTS";
export const GET_GROUPS = "GET_GROUPS";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const REMOVE_GROUP = "REMOVE_GROUP";
export const LOAD_MORE_GROUPS = "LOAD_MORE_GROUPS";
export const SEARCHING_STATUS = "SEARCHING_STATUS";
export const NEW_GROUP = "NEW_GROUP";
export const GROUP_NAME_UPDATED = "GROUP_NAME_UPDATED";

export const GET_PATIENTS = "GET_PATIENTS";

export const SET_APP_FIRST_LOAD = "SET_APP_FIRST_LOAD";
export const SET_APP_STATE = "SET_APP_STATE";
export const SET_IDLE_MODAL_STATE = "SET_IDLE_MODAL_STATE";
export const SET_ACTIVITY_LIST = "SET_ACTIVITY_LIST";

/**
	CONTACTS TYPES
**/
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const GET_CONTACTS = "GET_CONTACTS";
export const NEW_CONTACT = "NEW_CONTACT";
export const FILTER_CONTACTS = "FILTER_CONTACTS";
export const LOAD_MORE_CONTACTS = "LOAD_MORE_CONTACTS";
export const CONTACT_SEARCHING_STATUS = "CONTACT_SEARCHING_STATUS";
export const OTHER_USERS = "OTHER_USERS";
export const CONTACT_PROFILE = "CONTACT_PROFILE";
export const CONTACT_PROFILE_LOADING = "CONTACT_PROFILE_LOADING";

export const SET_SELECTED_CONTACTS = "SET_SELECTED_CONTACTS";
export const RESET_SELECTED_CONTACTS = "RESET_SELECTED_CONTACTS";

export const HAS_A_CALLER = "HAS_A_CALLER";
export const ACCEPT_CALL = "ACCEPT_CALL";
export const END_CALL = "END_CALL";
export const UPDATE_INSTALLATION_ID = "UPDATE_INSTALLATION_ID";
export const BEEN_FORWARDED_TO_CCM = "BEEN_FORWARDED_TO_CCM";

export const SAVE_DOCUMENT = "SAVE_DOCUMENT";
export const NEW_DOCUMENT = "NEW_DOCUMENT";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const DOCUMENT_FETCH_STATUS = "DOCUMENT_FETCH_STATUS";

export const ORGANIZATION_FETCH_STATUS = "ORGANIZATION_FETCH_STATUS";
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_IMAGE = "UPDATE_ORGANIZATION_IMAGE";
export const NEW_ORGANIZATION_MEMBERS = "NEW_ORGANIZATION_MEMBERS";
export const NEW_ORGANIZATION_GUESTS = "NEW_ORGANIZATION_GUESTS";
export const REMOVE_ORGANIZATION_MEMBERS = "REMOVE_ORGANIZATION_MEMBERS";
export const REMOVE_ORGANIZATION_GUESTS = "REMOVE_ORGANIZATION_GUESTS";
export const NEW_ADMIN_ORGANIZATION_MEMBERS = "NEW_ADMIN_ORGANIZATION_MEMBERS";
export const REMOVE_ADMIN_ORGANIZATION_MEMBERS = "REMOVE_ADMIN_ORGANIZATION_MEMBERS";
export const FETCH_ORGANIZATION_GROUPS = "FETCH_ORGANIZATION_GROUPS";
export const ADD_GROUP_TO_ORGANIZATION = "ADD_GROUP_TO_ORGANIZATION";
export const REMOVE_GROUP_TO_ORGANIZATION = "REMOVE_GROUP_TO_ORGANIZATION";

// TEAM
export const TEAM_FETCH_STATUS = "TEAM_FETCH_STATUS";
export const NEW_TEAM = "NEW_TEAM";
export const TEAM_NEW_NAME = "TEAM_NEW_NAME";
export const NEW_TEAM_MEMBERS = "NEW_TEAM_MEMBERS";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";

export const REVENUE_CAT_SUBSCRIBER = "REVENUE_CAT_SUBSCRIBER";

export const LABEL_RESULT = "LABEL_RESULT";
export const LABEL_STATUS = "LABEL_STATUS";
export const LABEL_ITEM_STATUS = "LABEL_ITEM_STATUS";

// GENERAL

export const IS_SEARCHING = "IS_SEARCHING";

// THREAD
export const SET_DRAFT_MESSAGE = "SET_DRAFT_MESSAGE";
export const SET_DEFAULT_DRAFT_MESSAGES = "SET_DEFAULT_DRAFT_MESSAGES";
export const SELECT_UNSELECT_MESSAGES = "SELECT_UNSELECT_MESSAGES";
export const MESSAGES_TO_FORWARD = "MESSAGES_TO_FORWARD";
export const OPEN_CLOSE_CHECKBOXES = "OPEN_CLOSE_CHECKBOXES";

// PHYSICIANS AND EDITORS

export const FETCH_PHYSICIANS_AND_EDITORS = "FETCH_PHYSICIANS_AND_EDITORS";
export const SET_ORGANIZATION_ROLE = "SET_ORGANIZATION_ROLE";
export const PHYSICIAN_ROLE_USERS = "PHYSICIAN_ROLE_USERS";
export const EDITOR_ROLE_USERS = "EDITOR_ROLE_USERS";

// PROGRESS NOTES
export const SET_PROGRESS_NOTES = "SET_PROGRESS_NOTES";
export const SET_PROGRESS_NOTES_PAGE = "SET_PROGRESS_NOTES_PAGE";
export const SET_PROGRESS_NOTES_ALL = "SET_PROGRESS_NOTES_ALL";
export const SET_PROGRESS_NOTES_PENDING = "SET_PROGRESS_NOTES_PENDING";
export const SET_PROGRESS_NOTES_DONE = "SET_PROGRESS_NOTES_DONE";
export const SET_PROGRESS_NOTES_RECENT = "SET_PROGRESS_NOTES_RECENT";
export const SET_PROGRESS_NOTES_PAGE_ALL = "SET_PROGRESS_NOTES_PAGE_ALL";
export const SET_DELETED_PROGRESS_NOTES = "SET_DELETED_PROGRESS_NOTES";
export const SET_DELETED_PROGRESS_NOTES_PAGE = "SET_DELETED_PROGRESS_NOTES_PAGE";
export const PROGRESS_NOTES_LOADING = "PROGRESS_NOTES_LOADING";
export const SET_PROGRESS_NOTE = "SET_PROGRESS_NOTE";
export const PROGRESS_NOTE_LOADING = "PROGRESS_NOTE_LOADING";
export const CREATE_PROGRESS_NOTE_SUCCESS = "CREATE_PROGRESS_NOTE_SUCCESS";
export const CREATE_PROGRESS_NOTE_FAILED = "CREATE_PROGRESS_NOTE_FAILED";
export const UPDATE_PROGRESS_NOTE_SUCCESS = "UPDATE_PROGRESS_NOTE_SUCCESS";
export const UPDATE_PROGRESS_NOTE_FAILED = "UPDATE_PROGRESS_NOTE_FAILED";
export const DELETE_PROGRESS_NOTE_SUCCESS = "DELETE_PROGRESS_NOTE_SUCCESS";
export const RESTORE_PROGRESS_NOTE_SUCCESS = "RESTORE_PROGRESS_NOTE_SUCCESS";
export const IS_CREATING_PROGRESS_NOTE = "IS_CREATING_PROGRESS_NOTE";
export const IS_UPDATING_PROGRESS_NOTE = "IS_UPDATING_PROGRESS_NOTE";
export const IS_DELETING_PROGRESS_NOTE = "IS_DELETING_PROGRESS_NOTE";
export const SET_PROGRESS_NOTES_PLANS = "SET_PROGRESS_NOTES_PLANS";
export const PROGRESS_NOTES_PLANS_LOADING = "PROGRESS_NOTES_PLANS_LOADING";
export const SET_DIAGNOSTIC_STUDIES = "SET_DIAGNOSTIC_STUDIES";
export const SET_REFERRALS = "SET_REFERRALS";
export const SET_SUPPLIES = "SET_SUPPLIES";
export const CHECK_UNCHECK_PLANS = "CHECK_UNCHECK_PLANS";
export const HAS_MORE_PROGRESS_NOTE = "HAS_MORE_PROGRESS_NOTE";

// CONFERENCES

export const SET_CONFERENCES_BY_ID = "SET_CONFERENCES_BY_ID";
export const SET_LOADING_CONFERENCES_BY_ID = "SET_LOADING_CONFERENCES_BY_ID";

// PATIENT ORDERS

export const SET_PATIENT_ORDERS = "SET_PATIENT_ORDERS";
export const PATIENT_ORDERS_LOADING = "PATIENT_ORDERS_LOADING";
export const SET_PATIENT_ORDER = "SET_PATIENT_ORDER";
export const PATIENT_ORDER_LOADING = "PATIENT_ORDER_LOADING";
export const CREATE_PATIENT_ORDER_SUCCESS = "CREATE_PATIENT_ORDER_SUCCESS";
export const CREATE_PATIENT_ORDER_FAILED = "CREATE_PATIENT_ORDER_FAILED";
export const IS_CREATING_PATIENT_ORDER = "IS_CREATING_PATIENT_ORDER";
export const UPDATE_PATIENT_ORDER_SUCCESS = "UPDATE_PATIENT_ORDER_SUCCESS";
export const UPDATE_PATIENT_ORDER_FAILED = "UPDATE_PATIENT_ORDER_FAILED";
export const DELETE_PATIENT_ORDER_SUCCESS = "DELETE_PATIENT_ORDER_SUCCESS";

// PATIENT ORDERS HISTORY

export const PATIENT_ORDERS_HISTORY_LOADING = "PATIENT_ORDERS_HISTORY_LOADING";
export const SET_PATIENT_ORDERS_HISTORY = "SET_PATIENT_ORDERS_HISTORY";

// PATIENT PRESCRIPTIONS HISTORY

export const IS_CREATING_PRESCRIPTION_HISTORY = "IS_CREATING_PRESCRIPTION_HISTORY";
export const CREATE_PRESCRIPTION_HISTORY_SUCCESS = "CREATE_PRESCRIPTION_HISTORY_SUCCESS";
export const CREATE_PRESCRIPTION_HISTORY_FAILED = "CREATE_PRESCRIPTION_HISTORY_FAILED";
export const PRESCRIPTIONS_HISTORY_LOADING = "PRESCRIPTIONS_HISTORY_LOADING";
export const PRESCRIPTION_HISTORY_LOADING = "PRESCRIPTION_HISTORY_LOADING";
export const SET_PRESCRIPTIONS_HISTORY = "SET_PRESCRIPTIONS_HISTORY";
export const SET_PRESCRIPTION_HISTORY = "SET_PRESCRIPTION_HISTORY";

export const IS_CREATING_GROUPED_LIST = "IS_CREATING_GROUPED_LIST";
export const GROUPED_LISTS_LOADING = "GROUPED_LISTS_LOADING";
export const GROUPED_LIST_LOADING = "GROUPED_LIST_LOADING";
export const SET_GROUPED_LISTS = "SET_GROUPED_LISTS";
export const SET_GROUPED_LIST = "SET_GROUPED_LIST";

export const E_SIGN_LOADING = "E_SIGN_LOADING";

export const TO_DOS_LOADING = "TO_DOS_LOADING";
export const HAS_MORE_TO_DOS = "HAS_MORE_TO_DOS";
export const SET_TO_DOS_ALL = "SET_TO_DOS_ALL";
export const SET_TO_DOS_ALL_HIGH = "SET_TO_DOS_ALL_HIGH";
export const SET_TO_DOS_ALL_MED = "SET_TO_DOS_ALL_MED";
export const SET_TO_DOS_ALL_ROUTINE = "SET_TO_DOS_ALL_ROUTINE";
export const SET_TO_DOS_PENDING = "SET_TO_DOS_PENDING";
export const SET_TO_DOS_PENDING_HIGH = "SET_TO_DOS_PENDING_HIGH";
export const SET_TO_DOS_PENDING_MED = "SET_TO_DOS_PENDING_MED";
export const SET_TO_DOS_PENDING_ROUTINE = "SET_TO_DOS_PENDING_ROUTINE";
export const SET_TO_DOS_CRITICAL = "SET_TO_DOS_CRITICAL";
export const SET_TO_DOS_DONE = "SET_TO_DOS_DONE";
export const SET_TO_DOS_DONE_HIGH = "SET_TO_DOS_DONE_HIGH";
export const SET_TO_DOS_DONE_MED = "SET_TO_DOS_DONE_MED";
export const SET_TO_DOS_DONE_ROUTINE = "SET_TO_DOS_DONE_ROUTINE";
export const SET_TO_DOS_RECENT = "SET_TO_DOS_RECENT";
export const SET_TO_DOS_PAGE_ALL = "SET_TO_DOS_PAGE_ALL";
export const SET_OPEN_CRITICAL = "SET_OPEN_CRITICAL";

export const IS_SENDING_MESSAGE = "IS_SENDING_MESSAGE";

export const SET_SIDE_NAV_TAB = "SET_SIDE_NAV_TAB";

export const SET_PATIENT_INFO_REDUCED = "SET_PATIENT_INFO_REDUCED";

export const SET_PATIENT_MEDICATIONS = "SET_PATIENT_MEDICATIONS";
export const IS_PATIENT_MEDICATIONS_LOADING = "IS_PATIENT_MEDICATIONS_LOADING";

export const SET_PATIENT_PROBLEMS = "SET_PATIENT_PROBLEMS";
export const IS_PATIENT_PROBLEMS_LOADING = "IS_PATIENT_PROBLEMS_LOADING";

export const SET_PATIENT_DIAGNOSES = "SET_PATIENT_DIAGNOSES";
export const IS_PATIENT_DIAGNOSES_LOADING = "IS_PATIENT_DIAGNOSES_LOADING";
export const SET_RECENT_SEARCH = "SET_RECENT_SEARCH";

export const SET_HAS_CRITICAL = "SET_HAS_CRITICAL";

export const SET_PREV_OPENED_THREAD = "SET_PREV_OPENED_THREAD";

export const SET_ATTENTIONED_MESSAGE = "SET_ATTENTIONED_MESSAGE";

export const SET_MESSAGES_SHOWN = "SET_MESSAGES_SHOWN";

export const SET_MESSAGES_BADGES = "SET_MESSAGES_BADGES";
export const MARK_AS_UNREAD = "MARK_AS_UNREAD";
