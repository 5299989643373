import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Parse from "parse";
import Time from "./Time";
import ContactMenu from "./ContactTab/ContactMenu";
import ContactRequestMenu from "./ContactTab/ContactRequestMenu";
import SelectControl from "./ContactTab/SelectControl";
import MessageMenu from "./MessageTab/MessageMenu";
import ReactTooltip from "react-tooltip";
import util from "../../helper/util";
import {
  sendNewMessage,
  setMessageTab,
  removeMessage,
  setPrevOpenedThread,
  setAttentionedMessage,
} from "../../actions/ThreadAction";
import { acceptContact } from "../../actions/ContactAction";
import { connect } from "react-redux";
import Spinner from "../Common/Spinner";
import { cancelRunningTask } from "react-pdf/dist/shared/utils";
import message from "../../use/message";

const $ = window.$;

export class Item extends Component {
  constructor(props) {
    super(props);
  }

  handleAccept(e) {
    this.cancelClick(e);
    this.props.acceptContact(this.props.data);
  }

  cancelClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleItemClick(data, type) {
    if (this.props.fromSearch) {
      this.props.closeSearch();
    }

    if (type === "message") {
      if (this.props.activeUnreadTab === "attention") {
        this.props.setAttentionedMessage(data.latestAttentionedMessage || null);
      }
      if (this.props.activeUnreadTab === "urgent") {
        this.props.setAttentionedMessage(data.latestUrgentMessage || null);
      }
      if (this.props.activeUnreadTab === "attention" && this.props.activeUnreadTab === "urgent") {
        this.props.setAttentionedMessage(null);
      }

      // if (localStorage.getItem("recentMesages") === null) {
      //   localStorage.setItem("recentMesages", JSON.stringify([data]));
      // } else {
      //   let recent = [data];
      //   let duplicate = false;
      //   let currentRecent = JSON.parse(localStorage.getItem("recentMesages") || "[]");
      //   currentRecent.map((data) => {
      //     if (recent.threadId === data.threadId) {
      //       duplicate = true;
      //     }
      //   });
      //   if (!duplicate) {
      //     const newRecent = [...recent, ...currentRecent];

      //     if (newRecent.length > 10) {
      //       newRecent.pop();
      //     }

      //     localStorage.setItem("recentMesages", JSON.stringify(newRecent));
      //   }
      // }
      // if (this.props.activeMessageTab !== "ALL") {
      //   this.props.setMessageTab("ALL");
      // }

      // if (this.props.prevOpenedThread) {
      //   this.props.removeMessage(this.props.prevOpenedThread);
      // }
      this.props.setActiveThread(data);
      this.props.setPrevOpenedThread(data);
      return;
    }

    if (type === "chart") {
      if (localStorage.getItem("recentPatients") === null) {
        localStorage.setItem("recentPatients", JSON.stringify([data]));
      } else {
        let patient = [data];
        let duplicate = false;
        let currentPatients = JSON.parse(localStorage.getItem("recentPatients") || "[]");
        currentPatients.map((data) => {
          if (patient.threadId === data.threadId) {
            duplicate = true;
          }
        });
        if (!duplicate) {
          const newPatient = [...patient, ...currentPatients];

          if (newPatient.length > 10) {
            newPatient.pop();
          }

          localStorage.setItem("recentPatients", JSON.stringify(newPatient));
        }
      }
      return;
    }

    this.props.createThread(data, type);
  }

  removeHtmlTags(str) {
    const removeHtml = str.replace(/<\/?[^>]+(>|$)/g, "");
    const removeSpace = removeHtml.replace(/^\s*[\r\n]/gm, "");
    return removeSpace;
  }

  render() {
    const { data, activeThread, type } = this.props;
    /**
     * Default Values
     */
    let currentUser = Parse.User.current();
    let containerStyle = {
      // border: "0px",
      paddingLeft: "72px",
      minHeight: "65px",
      // height: "65px",
      // borderRadius: "0px",
    };
    let patientColorScheme = {
      fontSize: "11px",
      fontWeight: "bold",
      float: "right",
      marginTop: "5px",
    };
    let Image,
      Name,
      Sender,
      Date,
      Text,
      isSubgroup = false,
      isCCMGroup = false,
      CCMCurrentPlan,
      Seen = true,
      TextLine,
      UnreadBadge,
      badgeColor = {},
      nameStyle = {};

    let patientThread = false,
      patientAdminTags = "";

    /**
     * default values for contacts
     */
    let mobileTag, BadgeTags;
    /**
     * default values for chart
     */
    let InactiveBadge, CCMDOB, CCMTags, contactMenu, checkBox, acceptButtons;

    let isActive = false;
    if (activeThread) {
      if (activeThread.threadId === data.threadId) {
        isActive = true;
      }
    }

    switch (type) {
      case "message":
        // if (data.hasAttention) badgeColor = {backgroundColor: "#F2BC52"}

        // if (data.hasUrgent) badgeColor = {backgroundColor: "#D84B55"}

        const unreadBadge = data.unreadMessagesCount > 99 ? "99+" : data.unreadMessagesCount;

        if (
          (data.hasUnreadAttention || data.hasUnreadUrgent || data.unreadMessagesCount) &&
          this.props.activeMessageTab !== "RECENT"
        )
          UnreadBadge = (
            <span
              className={
                data.unreadMessagesCount && this.props.activeUnreadTab === "personal"
                  ? "hc-badge-message btn-warning"
                  : data.hasUnreadAttention &&
                    (this.props.activeUnreadTab === "attention" ||
                      (this.props.activeUnreadTab !== "attention" &&
                        this.props.activeUnreadTab !== "urgent" &&
                        this.props.activeUnreadTab !== "personal"))
                  ? "hc-badge-message btn-warning"
                  : data.hasUnreadUrgent &&
                    (this.props.activeUnreadTab === "urgent" ||
                      (this.props.activeUnreadTab !== "attention" &&
                        this.props.activeUnreadTab !== "urgent" &&
                        this.props.activeUnreadTab !== "personal"))
                  ? "hc-badge-message btn-danger"
                  : data.unreadMessagesCount &&
                    !(
                      this.props.activeUnreadTab === "attention" ||
                      this.props.activeUnreadTab === "urgent" ||
                      this.props.activeUnreadTab === "personal"
                    ) &&
                    !(data.hasUnreadAttention || data.hasUnreadUrgent)
                  ? "hc-badge-message btn-primary"
                  : ""
              }
              style={{
                height: "10px",
                width: "10px",
                // color:
                //   data.hasAttention || data.hasUrgent || data.threadType === "private" || data.groupType === "patient"
                //     ? "#FFFFFF"
                //     : "#2194f3",
              }}
            ></span>
          );

        Text = data.threadDetail;
        if (data.latestAttentionedMessage && this.props.activeUnreadTab === "attention") {
          Text = data.latestAttentionedMessage.text;
        }
        if (data.latestUrgentMessage && this.props.activeUnreadTab === "urgent") {
          Text = data.latestUrgentMessage.text;
        }
        if (data.messageDate) {
          Date = data.messageDate.iso;
        }
        if (data.latestAttentionedMessage && this.props.activeUnreadTab === "attention") {
          Date = data.latestAttentionedMessage.originalMessageDate.iso;
        }
        if (data.latestUrgentMessage && this.props.activeUnreadTab === "urgent") {
          Date = data.latestUrgentMessage.originalMessageDate.iso;
        }

        switch (data.threadType) {
          case "private":
            Name = data.partnerName;
            if (typeof data.partnerImageURL === "undefined" || data.partnerImageURL === "") {
              Image = (
                <img src={require("../../assets/images/default.png")} alt="" className="profile-image rounded-circle" />
              );
            } else {
              Image = <img src={data.partnerImageURL} alt="" className="profile-image rounded-circle" />;
            }

            if (data.senderObjectId === currentUser.id) Sender = "You: ";

            TextLine = (
              <p className={"text-content mb-0 " + (Seen ? null : "unSeenMessage")}>
                <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
                {this.removeHtmlTags(Text)}
              </p>
            );
            break;

          case "group":
            Name = data.groupName.toUpperCase();
            var urlImage = require("../../assets/images/group-default.png");
            if (data.currentPlan === "none") {
              patientColorScheme.color = "#ff99c7";
              urlImage = require("../../assets/images/none-default.png");
            }
            if (data.currentPlan === "nf2f") {
              patientColorScheme.color = "rgb(238, 151, 73)";
              urlImage = require("../../assets/images/nf2f-default.png");
            }
            if (data.currentPlan === "ccm") {
              patientColorScheme.color = "#9550b3";
              urlImage = require("../../assets/images/ccm-default.png");
            }
            if (data.currentPlan === "tcm") {
              patientColorScheme.color = "#00b9ea";
              urlImage = require("../../assets/images/tcm-default.png");
            }
            if (data.currentPlan === "cpo") {
              patientColorScheme.color = "#23aaa0";
              urlImage = require("../../assets/images/cpo-default.png");
            }

            if (!data.groupImageURL) {
              Image = <img src={urlImage} alt="" className="profile-image rounded-circle" />;
            } else {
              Image = <img src={data.groupImageURL} alt="" className="profile-image rounded-circle" />;
            }
            if (data.senderObjectId === currentUser.id) {
              Sender = "You: ";
            } else {
              if (data.latestAttentionedMessage && this.props.activeUnreadTab === "attention") {
                Sender = data.latestAttentionedMessage.user.displayName + ": ";
              } else if (data.latestUrgentMessage && this.props.activeUnreadTab === "urgent") {
                Sender = data.latestUrgentMessage.user.displayName + ": ";
              } else {
                Sender = data.senderDisplayName + ": ";
              }
            }

            if (!data.senderObjectId) Sender = "";
            if (data.currentPlan) {
              isCCMGroup = true;
              patientThread = true;
              CCMCurrentPlan = data.currentPlan.toUpperCase();
              containerStyle.paddingTop = "6px";
              nameStyle.color = "#3e78b2";
            }
            if (data.subgroupSession) {
              isSubgroup = true;
              patientThread = false;
              containerStyle.paddingTop = "6px";
              nameStyle.color = "#d32f2f";
            }

            TextLine = (
              <p className={"text-content mb-0 " + (Seen ? null : "unSeenMessage")}>
                <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
                {this.removeHtmlTags(Text)}
              </p>
            );

            break;

          case "adhoc":
            if (!data.adhocGroupMembers) return null;
            let adhocName = [];

            for (let x = 0; x < data.adhocGroupMembers.length; x++) {
              const element = data.adhocGroupMembers[x];
              adhocName.push(element.name);
            }
            Name = adhocName.join(", ");

            if (data.senderObjectId === currentUser.id) {
              Sender = "You: ";
            } else {
              Sender = data.senderName + ": ";
            }

            Image = <img src={data.senderImageURL} alt="" className="profile-image rounded-circle" />;
            TextLine = (
              <p className={"text-content mb-0 " + (Seen ? null : "unSeenMessage")}>
                <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
                {this.removeHtmlTags(Text)}
              </p>
            );
            break;

          default:
            break;
        }
        Text = data.threadDetail;
        // Date = data.messageDate.iso;
        break;
      case "group":
        var group = data,
          groupImage;
        nameStyle.marginTop = "10px";
        if (group.image) {
          groupImage = group.image.url;
        } else {
          groupImage = require("../../assets/images/group-default.png");
        }

        Image = <img src={groupImage} alt="" className="profile-image rounded-circle" />;
        Name = group.name.toUpperCase();
        break;
      case "chart":
        var chart = data,
          currentPlan,
          chartImage;

        isCCMGroup = true; // default true since chart is ccm
        if (chart.ccmProgram) {
          if (chart.ccmProgram.currentPlan) {
            currentPlan = chart.ccmProgram.currentPlan;
            CCMCurrentPlan = currentPlan.toUpperCase();
          }
        }

        patientColorScheme = {
          fontSize: "11px",
          fontWeight: "bold",
          float: "right",
        };
        if (currentPlan === "none" || currentPlan === "" || !currentPlan) {
          patientColorScheme.color = "#ff99c7";
          chartImage = require("../../assets/images/none-default.png");
          CCMCurrentPlan = "NONE";
        }
        if (currentPlan === "nf2f") {
          patientColorScheme.color = "rgb(238, 151, 73)";
          chartImage = require("../../assets/images/nf2f-default.png");
        }
        if (currentPlan === "ccm") {
          patientColorScheme.color = "#9550b3";
          chartImage = require("../../assets/images/ccm-default.png");
        }
        if (currentPlan === "tcm") {
          patientColorScheme.color = "#00b9ea";
          chartImage = require("../../assets/images/tcm-default.png");
        }
        if (currentPlan === "cpo") {
          patientColorScheme.color = "#23aaa0";
          chartImage = require("../../assets/images/cpo-default.png");
        }

        if (typeof chart.image !== "undefined") {
          chartImage = chart.image.url;
          if (!chart.image.url) {
            chartImage = require("../../assets/images/group-default.png");
          }
        }

        if (chart.patientStatus === "inactive") {
          patientColorScheme.marginTop = "5px";
          InactiveBadge = (
            <span
              style={{
                display: "block",
                fontSize: "10px",
                borderRadius: "0.10rem",
              }}
              className="badge badge-secondary"
            >
              INACTIVE
            </span>
          );
        }
        var tags;
        if (chart.tags) {
          tags = chart.tags.join(" ");
          containerStyle.minHeight = "80px";
        }
        CCMDOB = (
          <p
            className="text-content mb-0"
            style={{
              display: "block",
              fontSize: "14px",
              color: " rgb(95, 93, 93)",
              margin: "0px",
            }}
          >
            {" "}
            {typeof chart.dob !== "undefined" ? chart.dob : "DOB: PENDING"}{" "}
          </p>
        );
        CCMTags = <p className="text-content mb-0">{tags}</p>;

        Image = <img src={chartImage} alt="" className="profile-image rounded-circle" />;
        Name = chart.name;
        break;
      case "contact":
        var contact = data.contact,
          contactURL;

        if (!contact) return null;
        // Name = contact.firstName +" "+contact.lastName;
        if (contact.displayName) {
          Name = contact.displayName;
        } else {
          Name = contact.firstName + " " + contact.lastName;
        }

        if (!contact.webOnly) {
          mobileTag = (
            <span
              style={{
                display: "inline-block",
                fontSize: "10px",
                borderRadius: "0.10rem",
                backgroundColor: "#029AD6",
                marginRight: "5px",
                padding: "2px 5px",
              }}
              className="badge badge-primary"
            >
              MOBILE
            </span>
          );
        } else {
          if (contact.statusMessage === "" || typeof contact.statusMessage === "undefined") {
            nameStyle.marginTop = "10px";
          }
        }
        if (contact.statusMessage) containerStyle.minHeight = "80px";

        if (typeof contact.picture === "undefined") {
          contactURL = require("../../assets/images/default.png");
        } else {
          contactURL = contact.picture.url;
        }
        Image = <img src={contactURL} alt="" className="profile-image rounded-circle" />;
        TextLine = (
          <p
            className="text-content mb-0"
            style={{
              display: "block",
              color: " rgb(95, 93, 93)",
              margin: "0px",
            }}
          >
            <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
            {contact.statusMessage}
          </p>
        );
        BadgeTags = (
          <p className="text-content mb-0">
            {contact.isPrimaryAccount && (
              <span
                style={{
                  display: "inline-block",
                  fontSize: "10px",
                  borderRadius: "0.10rem",
                  backgroundColor: "#20738D",
                  marginRight: "5px",
                  padding: "2px 5px",
                }}
                className="badge badge-primary"
              >
                MAIN
              </span>
            )}
            {mobileTag}
          </p>
        );
        contactMenu = <ContactMenu contact={contact} />;
        break;
      case "contact_request":
        var contact = data.contact,
          contactURL;

        if (!contact) return null;
        // Name = contact.firstName +" "+contact.lastName;
        if (contact.displayName) {
          Name = contact.displayName;
        } else {
          Name = contact.firstName + " " + contact.lastName;
        }

        if (!contact.webOnly) {
          mobileTag = (
            <span
              style={{
                display: "inline-block",
                fontSize: "10px",
                borderRadius: "0.10rem",
                backgroundColor: "#029AD6",
                marginRight: "5px",
                padding: "2px 5px",
              }}
              className="badge badge-primary"
            >
              MOBILE
            </span>
          );
        } else {
          if (contact.statusMessage === "" || typeof contact.statusMessage === "undefined") {
            nameStyle.marginTop = "10px";
          }
        }
        if (contact.statusMessage) containerStyle.minHeight = "80px";

        if (typeof contact.picture === "undefined") {
          contactURL = require("../../assets/images/default.png");
        } else {
          contactURL = contact.picture.url;
        }
        Image = <img src={contactURL} alt="" className="profile-image rounded-circle" />;
        TextLine = (
          <p
            className="text-content mb-0"
            style={{
              display: "block",
              color: " rgb(95, 93, 93)",
              margin: "0px",
            }}
          >
            <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
            {contact.statusMessage}
          </p>
        );
        acceptButtons = (
          <div className="mt-2">
            <button className="btn btn-sm btn-primary mr-1" onClick={(e) => this.handleAccept(e)}>
              Accept
            </button>
            <button className="btn btn-sm btn-danger" disabled>
              Decline
            </button>
          </div>
        );
        break;
      case "check_contact":
        var contact = data.contact,
          contactURL;

        if (!contact) return null;
        // Name = contact.firstName +" "+contact.lastName;
        if (contact.displayName) {
          Name = contact.displayName;
        } else {
          Name = contact.firstName + " " + contact.lastName;
        }

        if (!contact.webOnly) {
          mobileTag = (
            <span
              style={{
                display: "inline-block",
                fontSize: "10px",
                borderRadius: "0.10rem",
                backgroundColor: "#029AD6",
                marginRight: "5px",
                padding: "2px 5px",
              }}
              className="badge badge-primary"
            >
              MOBILE
            </span>
          );
        } else {
          if (contact.statusMessage === "" || typeof contact.statusMessage === "undefined") {
            nameStyle.marginTop = "10px";
          }
        }
        if (contact.statusMessage) containerStyle.minHeight = "80px";

        if (typeof contact.picture === "undefined") {
          contactURL = require("../../assets/images/default.png");
        } else {
          contactURL = contact.picture.url;
        }
        Image = <img src={contactURL} alt="" className="profile-image rounded-circle" />;
        TextLine = (
          <p
            className="text-content mb-0"
            style={{
              display: "block",
              color: " rgb(95, 93, 93)",
              margin: "0px",
            }}
          >
            <span style={{ fontWeight: "400", color: "black" }}>{Sender}</span>
            {contact.statusMessage}
          </p>
        );
        BadgeTags = (
          <p className="text-content mb-0">
            {contact.isPrimaryAccount && (
              <span
                style={{
                  display: "inline-block",
                  fontSize: "10px",
                  borderRadius: "0.10rem",
                  backgroundColor: "#20738D",
                  marginRight: "5px",
                  padding: "2px 5px",
                }}
                className="badge badge-primary"
              >
                MAIN
              </span>
            )}
            {mobileTag}
          </p>
        );
        checkBox = <SelectControl contact={contact} />;
        break;
      case "provider":
        const provider = data;

        if (typeof provider.companyPhoto !== "undefined") {
          urlImage = provider.companyPhoto.url;
        } else {
          urlImage = require("../../assets/images/group-default.png");
        }
        Image = <img src={urlImage} alt="" className="profile-image rounded-circle" />;
        Name = provider.companyName;
        TextLine = (
          <p
            className="text-content mb-0"
            style={{
              display: "block",
              color: " rgb(95, 93, 93)",
              margin: "0px",
            }}
          >
            {provider.providerType}
          </p>
        );
        break;
      default:
        break;
    }

    const isActiveThread = (path, match, location) => {
      return !!(match || path === location.pathname);
    };

    let idPath;
    if (type === "message") {
      if (data.threadType === "private") {
        idPath = data.partnerObjectId;
      } else if (data.threadType === "group") {
        idPath = data.threadId;
        if (data.subgroupSession) {
          idPath = data.subgroupSessionId;
        }
      } else if (data.threadType === "adhoc") {
        idPath = data.threadId;
      }
    } else {
      idPath = data.objectId;
    }

    const { isPatient } = this.props;

    if (isPatient) {
      delete containerStyle.height;
      containerStyle.paddingTop = "3px";
      containerStyle.paddingBottom = "3px";

      return (
        <NavLink
          data-tip
          data-for={"tooltip_" + data.objectId}
          to={"/u/" + idPath}
          onClick={this.handleItemClick.bind(this, data, type)}
          activeClassName="activeThread"
          isActive={isActiveThread.bind(this, "/u/" + idPath)}
          className="list-group-item list-group-item-action flex-column align-items-start"
          style={containerStyle}
        >
          <div>
            {Image}
            <div
              style={{
                position: "absolute",
                top: "58px",
                left: "5px",
                width: "60px",
                fontSize: `${data.dob ? "10px" : "8px" }`,
              }}
            >
              {data.dob ? data.dob : "DOB: PENDING" }
            </div>
            {this.props.showPatientBadge ? UnreadBadge : ""}
            <div className="row">
              <div className="col-md-12">
                <p className="name m-0" style={{ paddingRight: "unset" }}>
                  {Name.toUpperCase()}
                </p>
              </div>
              <div className="col-md-12">
                <p className="hc-primary-text truncate m-0">
                  <i className="fas fa-user mr-1"></i>
                  {data.owner ? <span className="text-blank">{data.owner.displayName}</span> : ""}
                </p>
              </div>
              <div className="col-md-12">
                <div className="hc-primary-text truncate m-0">
                  <span>
                    <i className="fas fa-credit-card mr-1"></i>
                  </span>
                  <span className="text-blank">{data.primaryInsurance ? data.primaryInsurance.name : "Insurance"}</span>
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      );
    }

    if (type === "check_contact") {
      return (
        <li
          onClick={this.handleItemClick.bind(this, data, type)}
          className="list-group-item list-group-item-action flex-column align-items-start"
          style={containerStyle}
        >
          <div>
            {Image}
            {data.groupType != "patient" ? UnreadBadge : this.props.showPatientBadge ? UnreadBadge : ""}
            <p className="name" style={nameStyle}>
              {Name}
            </p>
            {isSubgroup && (
              <p style={{ fontSize: "12px", color: "#283593" }} className="name">
                <span
                  style={{
                    background: "#283593",
                    position: "relative",
                    top: "-1px",
                  }}
                  className="badge badge-primary"
                >
                  SUBGROUP
                </span>{" "}
                {data.subgroupName}
              </p>
            )}
            {patientThread && (
              <p
                style={{
                  fontSize: "12px",
                  color: "#3e78b2",
                  textTransform: "uppercase",
                }}
                className="name"
              >
                <span
                  style={{
                    background: "#3e78b2",
                    position: "relative",
                    top: "-1px",
                  }}
                  className="badge badge-primary"
                >
                  ADMIN
                </span>{" "}
                {data.groupTags}
              </p>
            )}
            <div style={{ position: "absolute", bottom: "2px", right: "10px" }}>
              {Date && <Time date={Date} />}
              {InactiveBadge}
              {isCCMGroup && <span style={patientColorScheme}>{CCMCurrentPlan}</span>}
            </div>
            {(data.hasUnreadUrgent && this.props.activeUnreadTab === "urgent") ||
            (data.hasUnreadUrgent &&
              this.props.activeUnreadTab !== "urgent" &&
              this.props.activeUnreadTab !== "attention") ? (
              <div className="badge urgent-badge">
                {/* <i className="fas fa-exclamation-triangle fa-sm"></i> */}
                <span>URGENT</span>
              </div>
            ) : (data.hasUnreadAttention && this.props.activeUnreadTab === "attention") ||
              (data.hasUnreadAttention &&
                this.props.activeUnreadTab !== "urgent" &&
                this.props.activeUnreadTab !== "attention") ? (
              <div className="badge attention-badge">
                {/* <i className="fas fa-exclamation-triangle fa-sm"></i> */}
                <span>ATTENTION</span>
              </div>
            ) : (
              ""
            )}
            {TextLine}
            {BadgeTags}
            {CCMDOB}
            {CCMTags}
            {contactMenu}
            {checkBox}
            {type === "message" && this.props.activeMessageTab !== "RECENT" ? (
              <MessageMenu 
                recent={data} 
                recentId={data.objectId} 
                threadId={data.threadId} 
                isPinned={data.isPinned}
                activeThread={activeThread}
              />
            ) : (
              ""
            )}
            {type === "message" ? (
              <ReactTooltip
                ref={(el) => (this.tooltip = el)}
                id={"tooltip_" + data.objectId}
                onClick={this.cancelClick}
                className="Message-Tooltip"
                type="success"
                effect="solid"
                place="right"
                data-iscapture={true}
                data-scroll-hide={true}
                delayHide={100}
                delayUpdate={500}
                clickable={false}
              >
                {!(data.latestMessage || {}).attachments && (
                  <p
                    onClick={this.cancelClick}
                    style={{
                      fontSize: "16px",
                      maxWidth: "200px",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                      maxHeight: "300px",
                      overflowY: "auto",
                      scrollbarWidth: "auto",
                    }}
                  >
                    {this.removeHtmlTags(Text)}
                  </p>
                )}
                {(data.latestMessage || {}).attachments && data.latestMessage.attachments.length > 0 && (
                  <Fragment>
                    {!data.latestMessage.attachments[0].thumbNail && (
                      <p
                        onClick={this.cancelClick}
                        style={{
                          fontSize: "16px",
                          maxWidth: "250px",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          maxHeight: "300px",
                          overflowY: "auto",
                          scrollbarWidth: "auto",
                        }}
                      >
                        {this.removeHtmlTags(Text)}
                      </p>
                    )}
                    {data.latestMessage.attachments[0].thumbNail && (
                      <div style={{ maxHeight: 650, overflowY: "auto" }}>
                        <img
                          src={data.latestMessage.attachments[0].thumbNail.url}
                          style={{
                            marginBottom: 10,
                            width: "100%",
                            maxWidth: 430,
                          }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                <ConnectedReplyForm thread={data} tooltip={this.tooltip} />
              </ReactTooltip>
            ) : (
              ""
            )}
            {data.isPinned ? (
              <>
                <div className="pin-container">
                  <div className="pin-background"></div>
                </div>
                <i className="fa fa-thumbtack fa-xs pin-icon" style={{ color: "white" }}></i>
              </>
            ) : (
              ""
            )}
            <CircleLabels labels={data.labels} />
          </div>
        </li>
      );
    }

    return (
      <NavLink
        data-tip
        data-for={"tooltip_" + data.objectId}
        to={"/u/" + idPath}
        onClick={this.handleItemClick.bind(this, data, type)}
        activeClassName="activeThread"
        isActive={isActiveThread.bind(this, "/u/" + idPath)}
        className="list-group-item list-group-item-action flex-column align-items-start"
        style={containerStyle}
      >
        <div>
          {Image}
          {data.groupType != "patient" ? UnreadBadge : this.props.showPatientBadge ? UnreadBadge : ""}
          <p className="name" style={nameStyle} title={Name}>
            {Name}
          </p>
          {isSubgroup && (
            <p style={{ fontSize: "12px", color: "#283593" }} className="name">
              <span
                style={{
                  background: "#283593",
                  position: "relative",
                  top: "-1px",
                }}
                className="badge badge-primary"
              >
                SUBGROUP
              </span>{" "}
              {data.subgroupName}
            </p>
          )}
          {patientThread && (
            <p
              style={{
                fontSize: "12px",
                color: "#3e78b2",
                textTransform: "uppercase",
              }}
              className="name"
            >
              <span
                style={{
                  background: "#3e78b2",
                  position: "relative",
                  top: "-1px",
                }}
                className="badge badge-primary"
              >
                ADMIN
              </span>{" "}
              {data.groupTags}
            </p>
          )}
          <div style={{ position: "absolute", bottom: "2px", right: "10px" }}>
            {Date && <Time date={Date} />}
            {InactiveBadge}
            {isCCMGroup && <span style={patientColorScheme}>{CCMCurrentPlan}</span>}
          </div>
          {(data.hasUnreadUrgent && this.props.activeUnreadTab === "urgent") ||
          (data.hasUnreadUrgent &&
            this.props.activeUnreadTab !== "urgent" &&
            this.props.activeUnreadTab !== "attention") ? (
            <div className="badge urgent-badge">
              {/* <i className="fas fa-exclamation-triangle fa-sm"></i> */}
              <span>URGENT</span>
            </div>
          ) : (data.hasUnreadAttention && this.props.activeUnreadTab === "attention") ||
            (data.hasUnreadAttention &&
              this.props.activeUnreadTab !== "urgent" &&
              this.props.activeUnreadTab !== "attention") ? (
            <div className="badge attention-badge">
              {/* <i className="fas fa-exclamation-triangle fa-sm"></i> */}
              <span>ATTENTION</span>
            </div>
          ) : (
            ""
          )}
          {TextLine}
          {acceptButtons}
          {BadgeTags}
          {CCMDOB}
          {CCMTags}
          {contactMenu}
          {type === "message" && this.props.activeMessageTab !== "RECENT" ? (
            <MessageMenu 
              recent={data} 
              recentId={data.objectId} 
              threadId={data.threadId} 
              isPinned={data.isPinned}
              activeThread={activeThread}
            />
          ) : (
            ""
          )}
          {type === "message" ? (
            <ReactTooltip
              ref={(el) => (this.tooltip = el)}
              id={"tooltip_" + data.objectId}
              onClick={this.cancelClick}
              className="Message-Tooltip"
              type="success"
              effect="solid"
              place="right"
              data-iscapture={true}
              data-scroll-hide={true}
              delayHide={100}
              delayUpdate={500}
              clickable={false}
            >
              {!(data.latestMessage || {}).attachments && (
                <p
                  onClick={this.cancelClick}
                  style={{
                    fontSize: "16px",
                    maxWidth: "250px",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    maxHeight: "300px",
                    overflowY: "auto",
                    scrollbarWidth: "auto",
                  }}
                >
                  {this.removeHtmlTags(Text)}
                </p>
              )}
              {(data.latestMessage || {}).attachments && data.latestMessage.attachments.length > 0 && (
                <Fragment>
                  {!data.latestMessage.attachments[0].thumbNail && (
                    <p
                      onClick={this.cancelClick}
                      style={{
                        fontSize: "16px",
                        maxWidth: "250px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "auto",
                      }}
                    >
                      {this.removeHtmlTags(Text)}
                    </p>
                  )}
                  {data.latestMessage.attachments[0].thumbNail && (
                    <div style={{ maxHeight: 650, overflowY: "auto" }}>
                      <img
                        src={data.latestMessage.attachments[0].thumbNail.url}
                        style={{
                          marginBottom: 10,
                          width: "100%",
                          maxWidth: 430,
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              )}
              <ConnectedReplyForm thread={data} tooltip={this.tooltip} />
            </ReactTooltip>
          ) : (
            ""
          )}
          {data.isPinned ? (
            <>
              <div className="pin-container">
                <div className="pin-background"></div>
              </div>
              <i className="fa fa-thumbtack fa-xs pin-icon" style={{ color: "white" }}></i>
            </>
          ) : (
            ""
          )}
          <CircleLabels labels={data.labels} />
        </div>
      </NavLink>
    );
  }
}

class ReplyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      sending: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.createNewMessage = this.createNewMessage.bind(this);
  }

  handleChange(e) {
    const { value } = e.target;

    this.setState({ message: value });
  }

  componentDidMount() {
    $("form").keypress(function(e) {
      //Enter key
      if (e.which == 13) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  stopClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.message) return;

    this.setState({ sending: true });

    this.createNewMessage(this.state.message)
      .then((result) => {
        this.setState({
          message: "",
          sending: false,
        });
        this.props.tooltip.tooltipRef = null;
        ReactTooltip.hide();
      })
      .catch((error) => {
        debugger;
        this.setState({ sending: false });
      });
  }

  createNewMessage(composeMessage = "") {
    const { thread, isToolTipOpen } = this.props;

    const date = new Date();
    let newMessage = {
      text: composeMessage.trim(),
      originalMessageDate: date,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: util.randomID(),
      threadType: thread.threadType,
      threadId: thread.threadId,
      newMessageStatus: "sending",
      createdAt: date,
      attention: [],
      urgent: false,
      attentionName: [],
      priorityLevel: -1,
    };

    switch (thread.threadType) {
      case "private":
        newMessage.contact = {
          __type: "Pointer",
          className: "_User",
          objectId: thread.partnerObjectId,
        };
        break;

      case "group":
        newMessage.circle = {
          __type: "Pointer",
          className: "Circle",
          objectId: thread.threadId,
        };
        break;

      case "adhoc":
        newMessage.adhocGroupId = thread.threadId;
        break;

      default:
        break;
    }

    return this.props.sendNewMessage(newMessage, thread);
  }

  render() {
    const { message, sending } = this.state;

    const formStyle = {
      display: "flex",
      flexDirection: "row",
    };

    return (
      <div>
        <form style={formStyle}>
          <input
            disabled={sending}
            value={message}
            onChange={this.handleChange}
            style={{ width: "100%" }}
            className="quick-reply-input mr-2"
            onClick={(e) => this.stopClick(e)}
            placeholder="Type a reply..."
          />
          <button
            onClick={(e) => {
              this.handleClick(e);
            }}
            className="btn btn-sm btn-light"
            type="submit"
            style={{ minWidth: "53px" }}
          >
            {sending ? <i style={{ color: "#2194f3" }} className="fas fa-circle-notch fa-spin"></i> : "Send"}
          </button>
        </form>
      </div>
    );
  }
}

const CircleLabels = ({ labels = [] }) => {
  return (
    <div className="">
      {labels.map((label) => (
        <span className="badge badge-primary mr-1">{label.name}</span>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeMessageTab: state.thread.activeMessageTab,
  activeUnreadTab: state.thread.activeUnreadTab,
  prevOpenedThread: state.thread.prevOpenedThread,
});

const ConnectedReplyForm = connect(mapStateToProps, {
  sendNewMessage,
})(ReplyForm);

export default connect(mapStateToProps, {
  acceptContact,
  setMessageTab,
  removeMessage,
  setPrevOpenedThread,
  setAttentionedMessage,
})(Item);
